<template>
  <cuiAcl :roles="['underDevelopment']" redirect>
    <div class="card">
        <div class="card-body">
          <h4>Sammendrag</h4>
          <div class="row">
            <div class="col-md-4">
              Til utbetaling:
              <!-- <h5>{{ sumPayLines(payroll.payLines) }}</h5> -->
            </div>
          </div>
          <div class="col-md-12">
            <!-- <a-button @click="deletePayroll">Slett Lønnskjøring</a-button>
            <a-button @click="downloadData">Exporter til Tripletex</a-button>
            <a-button @click="payrollDone">Bekreft betaling i Tripletex</a-button> -->
          </div>
        </div>
      </div>
    <a-collapse accordion>
      <a-collapse-panel :header="payslip.user.employeeID.concat(' ', payslip.user.fullName, ' (', payslip.user.department, ')')" v-for="(payslip, index) in payslips" :key="payslip.id">
        <!-- {{ JSON.stringify(payslip.payLines) }} -->
        <div class="row">
          <div class="col-md-2">
            <a-avatar :size="64" icon="user" :src="payslip.user.imageUrl"/>
          </div>
          <div class="col-md-10">
            <h4>{{ payslip.user.employeeID }} {{payslip.user.fullName }} {{ payslip.user.department }}</h4>
          </div>
          <div class="col-md-12">
            <a-table :columns="payLineColumns" :data-source="payslip.payLines" bordered class="mt-3" :pagination="false">
              <template slot="sum" slot-scope="text, record">
                {{ Number(record.Amount) * Number(record.Count) }}
              </template>
              <template slot="footer">
                Til utbetaling: {{ payslip.totalAmount }}
              </template>
            </a-table>
            <a-divider />
            <a-button @click="generateCertificate(index)">Generer betalingssertifikat og send til godkjenning</a-button>
          </div>
        </div>
      </a-collapse-panel>
    </a-collapse>
  </cuiAcl>
</template>

<script>
import { firestore, functions, storage } from '@/services/firebase'
import cuiAcl from '@/components/cleanui/system/ACL'
import moment from 'moment'
import { openNotification } from '@/services/powerAutomate'
import { getWageTypeName, fetchWageTypeNames } from '@/services/common/payroll'

export default {
  components: {
    cuiAcl,
  },
  data() {
    return {
      payslips: [],
      wageTypeNames: [],
      payLineColumns: [
        {
          title: 'Lønnsrader',
          dataIndex: 'WageTypeNumber',
          customRender: (text) => getWageTypeName(text, this.wageTypeNames),
        },
        {
          title: 'Prosjekt',
          dataIndex: 'ProjectNumber',
        },
        {
          title: 'Avdeling',
          dataIndex: 'DepartmentNumber',
        },
        {
          title: 'Antall',
          dataIndex: 'Count',
        },
        {
          title: 'Sats',
          dataIndex: 'Amount',
        },
        {
          title: 'Til utbetaling',
          dataIndex: 'sum',
          scopedSlots: { customRender: 'sum' },
        },
      ],
    }
  },
  async mounted() {
    const { wageTypeNames } = await fetchWageTypeNames()
    this.wageTypeNames = wageTypeNames
    await this.getConsultants()
    this.setWageTypeName()
    // change wageTypeNumber to wageTypeName
  },
  methods: {
    getWageTypeName,
    async getConsultants() {
      firestore.collection('payslips')
        .where('employeeType', '==', 'consultant')
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(async (doc) => {
            const payslip = doc.data()
            payslip.id = doc.id
            payslip.user = await this.getUserData(payslip.employeeID)
            payslip.payLines = payslip.payLines.map(payLine => ({ ...payLine, Amount: Math.round(payLine.Amount * 1.2808 * 100) / 100 }))
            payslip.totalAmount = Math.round(payslip.totalAmount * 1.2808 * 100) / 100
            this.payslips.push(payslip)
            console.log('payslip', payslip)
          })
        })
    },
    setWageTypeName() {
      this.payslips.forEach((payslip, i) => {
        console.log('payslip', payslip)
        payslip.payLines.forEach((payLine, j) => {
          const wageTypeName = this.getWageTypeName(payLine.WageTypeNumber)
          console.log(wageTypeName)
          this.payslips[i].payLines[j].WageTypeName = wageTypeName
        })
      })
    },
    async getUserData(employeeID) {
      const usersRef = firestore.collection('users')
      const querySnapshot = await usersRef.where('employeeID', '==', employeeID).get()

      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0]
        const userData = {
          employeeID: userDoc.data().employeeID,
          fullName: userDoc.data().fullName,
          department: userDoc.data().department,
          status: userDoc.data().status,
          imageUrl: userDoc.data().imageUrl,
          consultant: userDoc.data().consultant,
        }
        return userData
      } else {
        console.error(`User data not found for employeeID: ${employeeID}`)
        return null
      }
    },
    async generateCertificate(i) {
      this.setWageTypeName()
      // get paymentCertificate id from variables collection and add 1
      const variablesRef = firestore.collection('variables').doc('paymentCertificate')
      const variablesDoc = await variablesRef.get()
      const paymentCertificateId = variablesDoc.data().value + 1
      // update paymentCertificate id in variables collection
      variablesRef.update({ value: paymentCertificateId })
      const payslip = this.payslips[i]
      const consultantInfo = payslip.user.consultant
      const company = await this.fetchCompany(payslip.company)
      const document = {
        paymentCertificateId,
        amount: payslip.totalAmount * 1.2808,
        consultantOrganizationNumber: consultantInfo.organizationNumber,
        consultantCompanyName: consultantInfo.companyName,
        consultantAddressLine1: consultantInfo.companyAddress.addressLine1,
        consultantCity: consultantInfo.companyAddress.city,
        consultantPostalCode: consultantInfo.companyAddress.postalCode,
        date: String(moment().format('DD.MM.YYYY')),
        period: payslip.period,
        companyCompanyName: company.name,
        companyOrganizationNumber: company.organizationNumber,
        companyAddressLine1: company.address.addressLine1,
        companyCity: company.address.city,
        companyPostalCode: company.address.postalCode,
        invoiceEmail: company.invoiceEmail,
        payLines: payslip.payLines,
      }
      // make all values inside document into strings
      Object.keys(document).forEach((key) => {
        if (typeof document[key] === 'number') {
          document[key] = String(document[key])
        }
      })
      // remove transactionIDs from payLines
      // document.payLines.forEach((payLine, i) => {
      //   delete document.payLines[i].transactionIDs
      // })
      try {
        // Define your parameters here

        const docTemplate = {
          fullPath: 'contracts/Consultants_Payment_Certificate.docx',
        }
        const fileName = `tasksAttachments/${consultantInfo.companyName} Payment Certificate ${payslip.period}`
        const destination = `tasks/${fileName}`

        const data = {
          document,
          destination,
          docTemplate,
        }

        // Call the generateDoc Cloud Function
        const generateDoc = functions.httpsCallable('generateDoc')
        const result = await generateDoc(data)

        if (result.data.message === 'success') {
          // get document url
          storage.ref(destination.concat('.docx')).getDownloadURL()
            .then((documentUrl) => {
              // create task
              const taskData = {
                title: `Send Payment Certificate to ${consultantInfo.companyName}`,
                description: `A payment certificate has been generated for ${consultantInfo.companyName} for the period ${payslip.period}. Please send the certificate to the following email: ${consultantInfo.invoiceEmail}.`,
                date: String(moment().format('YYYY-MM-DDTHH:mm')),
                status: 'to-do',
                type: 'payroll',
                attachments: [
                  {
                    name: fileName,
                    url: documentUrl,
                    type: 'file',
                  },
                ],
                callbacks: [
                  {
                    fn: 'sendEmail',
                    name: 'Godkjenn og send til konsulent',
                    type: 'Approval',
                    data: {
                      to: consultantInfo.email,
                      cc: this.$store.state.user.email2,
                      subject: `Payment Certificate for ${consultantInfo.companyName} for the period ${payslip.period}`,
                      body: `Please find the attached payment certificate for ${payslip.user.name} for the period ${payslip.period}.`,
                      attachments: [
                        {
                          name: fileName,
                          url: documentUrl,
                          type: 'file',
                        },
                      ],
                    },
                  },
                ],
                // Add any additional callbacks or data you need
              }
              console.log(taskData)
              this.createTask(taskData)
            })
          openNotification('Certificate generated successfully')
        } else {
          openNotification('An error occurred while generating the certificate')
        }
      } catch (error) {
        console.error('Error:', error)
        openNotification('An error occurred while generating the certificate')
      }
    },
    // fetch company data from firestore collection settings doc companies subcollection companies where id = company
    async fetchCompany(company) {
      const companyRef = firestore.collection('settings').doc('companies').collection('companies').doc(company)
      const companyDoc = await companyRef.get()
      return companyDoc.data()
    },
    async createTask(taskData) {
      const taskRef = firestore.collection('tasks')
      await taskRef.add(taskData)
    },
  },
}
</script>

<style>

</style>
